@import 'normalize.css';
@import 'icons.scss';
@import 'loading.scss';

$column-width: 40rem;
$roundness: .25rem;

$secondary-colour: #eee;
$word-colour: #f3f3f3;

@mixin body-font {
	font-family: 'Rubik', serif;
	font-weight: 400;
}

@mixin display-font {
	font-family: 'Balsamiq Sans', cursive;
	font-weight: 400;
}

#app {
	width: 100%;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	@include body-font();
}

#app > div:not(#error-list) {
	max-width: $column-width;
	width: calc(100% - 2rem);
}

.word {
  display: grid;
  grid-template-columns: 2rem 50% 1fr;
  grid-template-rows: minmax(1.5rem, auto) 1fr;
  grid-template-areas: "icon key key" "main main trans";

  border: solid #333 1px;
  border-radius: $roundness;
  margin-top: .75rem;
  margin-bottom: .75rem;
//   padding: .25rem;
	overflow: hidden;

}

.word-icon {
	grid-area: icon;
	font-size: 1.5em;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: $word-colour;
	padding-top: .25rem;
	padding-bottom: .25rem;
}

.word-key {
	grid-area: key;
	background: $word-colour;
	padding-top: .25rem;
	padding-bottom: .25rem;
	padding-right: .25rem;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	& .quick-info {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		flex-grow: 1;
		justify-content: space-between;
		& > * {
			display: block;
		}
		& > .comment {
			font-style: italic;
		}
	}
	& > .comment {
		justify-self: flex-end;
	}
}

.word-main {
	grid-area: main;
	background-color: #f8f8f8;
	padding: .25rem;
}

.word-ti {
	grid-area: trans;
	background-color: #f8f8f8;
	padding: .25rem;
	& > .word-translations, & > .word-inflections, &  > .word-synonyms {
		& ul {
			padding-left: 1rem;
		}
	}
}

.word-phonetic {
	padding-top: .25rem;
	& .title {
		font-weight: 700;
	}
}

nav {
	width: 100%;
	height: 2rem;
	position: sticky;
	align-self: flex-start;
	top: 0;
	background: $secondary-colour;
	@include display-font();
	display: flex;
	flex-direction: row;
	align-items: center;
	& * {
		margin-left: 1rem;
	}

	& > img {
		height: 1.5rem;
		width: 1.5rem;
	}
}

#search {
	width: 100%;
	display: flex;
	flex-direction: row;
	margin-top: 1.5rem;
	margin-bottom: .75rem;
	& > * {
		border: 1px black solid;
		border-radius: .25rem;
	}
	& > #search-input {
		flex-grow: 1;
		height: 1.5rem;
		padding-top: .5rem;
		padding-bottom: .5rem;
		padding-left: .25rem;
		padding-right: .25rem;
	}

	& > #search-submit {
		flex-grow: 0;
		height: 1.5rem;
		background: $secondary-colour;
		color: black;
	}
}


.def-word {
	color: black;
	& .separator {
		color: #666;
	}
}

.word-translations, .word-inflections, .word-synonyms {
	& .title {
		font-weight: 700;
	}
	& ul {
		margin-top: .25rem;
		margin-bottom: .25rem;
		& > li > span:nth-child(2) {
			width: 100%;
			font-style: italic;
		}
	}
}

.word-definition, .word-explanation {
	& .title {
		font-weight: 700;
	}
	& ul {
		margin-top: .25rem;
		margin-bottom: .25rem;
		padding: 0;
		margin-left: 0;
		margin-right: 0;
		list-style: none;
		& li.swe-bullet, & li.eng-bullet  {
			padding-left: 1rem;
			text-indent: -0.75rem;
		}
		& li.swe-bullet {
			&::before {
				content: '🇸🇪\00a0';
			}
		}
		& li.eng-bullet {
			&::before {
				content: '🇬🇧\00a0';
			}
		}
	}
}

#dictionary-reference {
	display: flex;
	width: 100%;
	flex-wrap: wrap;
	flex-direction: row;
	row-gap: .25rem;
	margin-top: .5rem;
	& > span {
		width: 10rem;
	}
}

code {
	background-color: $secondary-colour;
	padding-left: .125rem;
	padding-right: .125rem;
	border-radius: .125rem;
	white-space: nowrap;
}

nav > a {
	color: black;
	text-decoration: underline;
	cursor: pointer;
	&:visited {
		color: black;
	}
}

footer {
	@include display-font();
	margin-bottom: .5rem;
}

#dictionary-description {
	& > img {
		height: 8rem;
		width: 8rem;
		float: right;
		margin: 2rem;
		margin-right: 0rem;
	}
}
.word-idioms {
	padding-top: .25rem;
	& .title {
		font-weight: 700;
	}
	& ul {
		margin-top: .25rem;
		margin-bottom: .25rem;
		& > li > span:nth-child(2) {
			width: 100%;
			font-style: italic;
		}
	}
}

#results {
	& > #title {
		& > * {
			font-style: italic;
		}
	}
}

#switch-button {
	text-decoration: underline;
	color: black;
	cursor: pointer;
}

#error-list{
	position: fixed;
	right: 0;
	bottom: 0;
	display: flex;
	max-width: 15rem;
	justify-content: flex-end;
	width: 100%;
	flex-direction: column;
	margin-left: .25rem;
	margin-right: .25rem;
	box-sizing: border-box;
	& > .error-message {
		box-sizing: inherit;
		background: pink;
		width: 100%;
		border-radius: .25rem;
		border: 1px solid #333;
		margin-top: .25rem;
		margin-bottom: .25rem;
		padding: .25rem;
		& > .name {
			font-weight: 700;
			display: block;
		}
		& > .dismiss {
			float: right;
		}
		& > .message {
			font-size: .8rem;
		}
	}
}