.loading-background {
    animation: blue-loading 1s infinite ease-in-out alternate;
}

@keyframes blue-loading {
    0% {
        background-color: rgb(129, 201, 224);
    }
    100% {
        background-color: rgb(81, 202, 243);
    }
}